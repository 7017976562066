import store from "@/store/store";
import i18n from "@/i18n";
import { clone } from "ramda";
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib';
import { parseTimestamp, getDateTimeRange } from "../tools/functions";

export const breadcrumbService = (function () {
    "use strict";

    function setSummaryDetailsByFilters(filters, reportType) {
        const details = getFilterDetailsHTML(filters, reportType);
        store.dispatch("breadcrumb/setDetails", details);
    }

    function getFilterDetailsHTMLByThing(filters, thingName) {
        const newFilters = clone(filters);
        if (!newFilters.generalData) {
            newFilters.generalData = {};
        }
        newFilters.generalData.selectedThings = [];
        newFilters.generalData.selectedThings.push({
            enterpriseId: 1,
            name: thingName
        });
        return this.getFilterDetailsHTML(newFilters);
    }

    function getFilterDetailsHTML(filters, reportType) {
        let details = '';
        if (reportType) {
            details += `<b>${i18n.t('breadcrumb.details.reportType.' + reportType)}</b><hr>`
        }
        if (filters) {
            if (
                filters.generalData &&
                filters.generalData.selectedThings &&
                filters.generalData.selectedThings.length > 0
            ) {
                details += `<b>${i18n.t('breadcrumb.details.things')}: </b>`
                const names = getThingNames(filters.generalData.selectedThings);
                for (const name of names) {
                    details += `${name},`
                }
                details += '<br>'
            }
            if (filters.dateData) {
                details += `<b>${i18n.t('breadcrumb.details.reportPeriod')}: </b>`
                const reportFixedRange = filters.dateData.selectedDateAndTimeRange;
                details += `${i18n.t('reportFixedRange.' + reportFixedRange)}`
                details += '<br>'
                if (reportFixedRange === TimeRanges.CUSTOM) {
                    const sinceDate = formatDate(filters.dateData.sinceDate);
                    const toDate = formatDate(filters.dateData.toDate);
                    if (filters.dateData.selectedDateAndTimeCustomRangeType === CustomTimeRangeTypes.DATE) {
                        details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                        details += `${sinceDate}`;
                        details += "<br>";
                        details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                        details += `${toDate}`;
                        details += '<br>';
                    } else {
                        details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                        details += `${sinceDate} ${filters.dateData.sinceTime}`;
                        details += '<br>';
                        details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                        details += `${toDate} ${filters.dateData.toTime}`;
                        details += '<br>';
                    }
                }
            }
            if (filters.orderData && reportType != "CALIPER_POSITIONS_REPORT") {
                details += `<b>${i18n.t('breadcrumb.details.sortType')}: </b>`
                const sortType = filters.orderData.selectedSortOption;
                details += `${i18n.t('sortTypes.' + sortType, { propName: i18n.t("filters.tabOrder.worstBest") })}`
                details += '<br>'
            }
            /* if (filters.configurationsData && filters.configurationsData.selectedYieldUnit && reportType === "PERFORMANCE") {
                details += `<b>${i18n.t('breadcrumb.details.yieldUnit')}: </b>`
                const yieldUnit = filters.configurationsData.selectedYieldUnit;
                details += `${i18n.t('yieldUnits.' + yieldUnit)}`
                details += '<br>'
            } 
            if (filters.rangeData) {
                if (filters.rangeData.greaterThan || filters.rangeData.greaterThan === 0) {
                    details += `<b>${i18n.t('breadcrumb.details.rangeGreatThan')} </b> ${filters.rangeData.greaterThan}`
                    details += '<br>'
                }
                if (filters.rangeData.lessThan || filters.rangeData.lessThan === 0) {
                    details += `<b>${i18n.t('breadcrumb.details.rangeLessThan')} </b> ${filters.rangeData.lessThan}`
                    details += '<br>'
                }
            }*/
        }
        return details;
    }

    function getThingNames(groups) {
        let result = [];
        for (const group of groups) {
            if (group.enterpriseId) {
                result.push(group.name);
            }
            if (group.children) {
                result = result.concat(getThingNames(group.children));
            }
        }
        return result;
    }

    function formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
    }

    function getFilterDetailsHTMLExport(filters, reportType = null, thingName = null) {
        let details = '';
        if (reportType) {
            details += `<b>${i18n.t('breadcrumb.details.reportType.' + reportType)}</b><hr>`
        }
        if (thingName) {
            details += `<b>${i18n.t('breadcrumb.details.things')}: ${thingName}</b><br>`;
        } else if (
            filters.generalData &&
            filters.generalData.selectedThings &&
            filters.generalData.selectedThings.length > 0) {
            details += `<b>${i18n.t('breadcrumb.details.things')}: </b>`;
            const names = getThingNames(filters.generalData.selectedThings);
            for (const name of names) {
                details += `${name},`;
            }
            details += '<br>';
        }
        if (filters.dateData) {
            const reportFixedRange = filters.dateData.selectedDateAndTimeRange;
            if (reportFixedRange === TimeRanges.CUSTOM) {
                const sinceDate = formatDate(filters.dateData.sinceDate);
                const toDate = formatDate(filters.dateData.toDate);
                if (filters.dateData.selectedDateAndTimeCustomRangeType === CustomTimeRangeTypes.DATE) {
                    details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                    details += `${sinceDate}`;
                    details += "<br>";
                    details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                    details += `${toDate}`;
                    details += '<br>';
                } else {
                    details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                    details += `${sinceDate} ${filters.dateData.sinceTime}`;
                    details += '<br>';
                    details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                    details += `${toDate} ${filters.dateData.toTime}`;
                    details += '<br>';
                }
            } else {
                const { tFrom, tTo } = getDateTimeRange(reportFixedRange, null, null, null, null, null);
                details += `<b>${i18n.t('breadcrumb.details.from')}: </b>`;
                details += `${parseTimestamp(tFrom)} - `;
                details += `<b>${i18n.t('breadcrumb.details.to')}: </b>`;
                details += `${parseTimestamp(tTo)}`;
                details += '<br>';
            }
        }
        if (filters.configurationsData && filters.configurationsData.selectedYieldUnit && reportType === "PERFORMANCE") {
            details += `<b>${i18n.t('breadcrumb.details.yieldUnit')}: </b>`
            const yieldUnit = filters.configurationsData.selectedYieldUnit;
            details += `${i18n.t('yieldUnits.' + yieldUnit)}`
            details += '<br>'
        }
        if (filters.orderData) {
            details += `<b>${i18n.t('breadcrumb.details.sortType')}: </b>`
            const sortType = filters.orderData.selectedSortOption;
            details += `${i18n.t('sortTypes.' + sortType, { propName: i18n.t("dischargeSummary.filterProperties.worstBestProperty") })}`
            details += '<br>'
        }
        if (filters.rangeData) {
            if (filters.rangeData.greaterThan || filters.rangeData.greaterThan === 0) {
                details += `<b>${i18n.t('breadcrumb.details.rangeGreatThan')} </b> ${filters.rangeData.greaterThan}`
            }
            if (filters.rangeData.lessThan || filters.rangeData.lessThan === 0) {
                details += ` - <b>${i18n.t('breadcrumb.details.rangeLessThan')} </b> ${filters.rangeData.lessThan}`
                details += '<br>'
            }
        }

        return details;
    }

    function addDaysFilters(daysList) {
        store.dispatch("breadcrumb/setEntitySelectorConfiguration", {
            key: "daysSelector",
            config: {
                name: i18n.t("Dias"),
                value: "filterDates",
                text: "date"
            }
        });
        const daysSelector = {
            key: "daysSelector",
            items: daysList
        };
        store.dispatch("breadcrumb/setEntitySelectorItems", daysSelector);
    }

    function cleanById(selectorId) {
        store.dispatch("breadcrumb/cleanById", selectorId);
    }

    function cleanSelectorsById(selectorId) {
        store.dispatch("breadcrumb/cleanSelectorsById", selectorId);
    }

    return {
        setSummaryDetailsByFilters,
        getFilterDetailsHTML,
        getFilterDetailsHTMLByThing,
        getFilterDetailsHTMLExport,
        addDaysFilters,
        cleanById,
        cleanSelectorsById
    }
})();