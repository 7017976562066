import { mapGetters } from 'vuex'

export default {
  name: 'AutocompleteComponent',
  components: {},
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    placeholder: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    optionSelectorItems: {
      type: Array,
      required: true
    },
    itemText: {
      required: false
    },
    itemValue: {
      required: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    filled: {
      type: Boolean,
      required: false,
      default: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    prependIcon: {
      required: false
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'fa-caret-down'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      required: false,
      default: 1
    },
    vModel: {
      required: false
    },
    vIf: {
      type: Boolean,
      required: false,
      default: true
    },
    rules: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    vShow: {
      type: Boolean,
      required: false,
      default: true
    },
    height: {
      type: Number,
      required: false
    },
    errorMessages: {
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    clearIcon: {
      type: String,
      required: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    optionSelected: null,
    defaultErrorMessages: ''
  }),
  computed: {
    /**
     * getters del breadcrumb para el dialog
     */
    ...mapGetters('breadcrumbs', [
      'getBread'
    ])
  },
  created () {
  },
  beforeDestroy () {
  },
  mounted () {
    if (this.vModel) {
      this.optionSelected = this.vModel
    }
  },
  methods: {
    sortSearch (item, queryText) {
      const index = this.itemText.indexOf('.')
      let itemNormalice
      if (index >= 0) {
        const itemTextA = this.itemText.substr(0, index)
        const itemTextB = this.itemText.substr(index + 1)
        itemNormalice = item[itemTextA][itemTextB].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      } else {
        itemNormalice = item[this.itemText].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      }
      const queryTextNormalice = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return (itemNormalice.indexOf(queryTextNormalice) > -1)
    },
    clearAutocomplete () {
      this.$emit('clearFunction', true)
    }
  },
  watch: {
    optionSelected (val) {
      this.$emit('optionSelectedFunction', val)
    },
    'vModel': {
      handler: function () {
        if (this.vModel) {
          this.optionSelected = this.vModel
        }
      },
      deep: true
    }
  }
}
