import { mapGetters, mapActions } from 'vuex'
// import { clone } from 'ramda';
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'

export default {
  name: 'BreadcrumbComponent',
  components: {
    AutocompleteComponent
  },
  props: {
    /**
     * tab : {
     *  id: <identificador único del tab>,
     *  name: <nombre del tab>
     * }
     */
    tabs: {
      type: Array,
      required: true
    },
    tabSelected: {
      type: Function,
      required: true
    },
    buttons: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },
  data() {
    return {
      selectedEntities: []
    }
  },
  mounted() {

    const search = new URLSearchParams(window.location.search)

    const selectedtab = search.get('tab')

    if (selectedtab) {
      this.tabSelected(this.tabs[selectedtab - 1].id)
    }
  },
  computed: {
    ...mapGetters({
      getEntitySelectorItems: 'breadcrumb/getEntitySelectorItems',
      getSelectedItem: 'breadcrumb/getSelectedItem',
      getEntitySelectorConfiguration: 'breadcrumb/getEntitySelectorConfiguration',
      getDetails: 'breadcrumb/getDetails'
    })
  },
  created() {
  },
  beforeDestroy() {
  },
  methods: {
    ...mapActions('breadcrumb', [
      'setSelectedItem'
    ]),
    selectedFunction(newValue, key) {
      if (newValue) {
        this.selectedEntities[newValue.key] = newValue;
        this.$emit('select', { ...this.selectedEntities, eventKey: key });
      }
    }
  },
}
